import moment from "moment";

export default {
      
      

      goToRouter(view) {
            this.$router.push({name: view});
      },      
      noToTime(hh, mm) {

            let hours = parseInt(hh);
            let minutes =  parseInt(mm);
      
            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
      
            return ''+hours+':'+minutes+':00';
      },
      
      minToTime(min) {
            let h = Math.floor(min / 60);
            let m = min % 60;
            h = h < 10 ? '0' + h : ''+h; 
            m = m < 10 ? '0' + m : ''+m; 
      
            return h + ':' + m;
      },
          
      minToTimeObj(min) {
            let h = Math.floor(min / 60);
            let m = min % 60;
            h = h < 10 ? '0' + h : ''+h; 
            m = m < 10 ? '0' + m : ''+m; 
      
            return {
              hours: h,
              minutes: m
            }
      },
          
      timeToMin(time) {
            let h = parseInt(time.substring(0,2),10);
            let m = parseInt(time.substring(time.indexOf(':')+1));
      
            return (h * 60) + m;
      
      },

      CookieCheckRedirect: function(goTo) {
            if (this.$cookies.isKey("_ts_access_token") && this.$cookies.isKey("_ts_agency_id") && this.$cookies.isKey("_ts_role") && this.$cookies.isKey("_ts_team") && this.$cookies.isKey("_ts_user_id") ) {
              this.$router.push({name: goTo});
            }
           
      },

      CookieCheckGoToLogin: function() {
            if (!(this.$cookies.isKey("_ts_access_token") && this.$cookies.isKey("_ts_agency_id") && this.$cookies.isKey("_ts_role") && this.$cookies.isKey("_ts_team") && this.$cookies.isKey("_ts_user_id"))) {
              this.$router.push({name: "Login"});
            } 
            else {
                  this.$store.commit('setUser', {
                        accessToken: this.$cookies.get("_ts_access_token"),
                        userName: this.$cookies.get("_ts_firstname")+' '+this.$cookies.get("_ts_lastname"),
                        role: this.$cookies.get("_ts_role"),
                        team: this.$cookies.get("_ts_team"),
                        teamId: this.$cookies.get("_ts_team_id"),
                        agency: this.$cookies.get("_ts_agency"),
                        agencyId: this.$cookies.get("_ts_agency_id"),
                        userId: this.$cookies.get("_ts_user_id"),
                        });

                  let val2 = JSON.parse(localStorage.userSettings);
                  this.$store.commit('setUserSettings', val2);

                  let val3 = JSON.parse(localStorage.userFunction);
                  this.$store.commit('setUserFunction', val3);


                  let val2Len = this.$store.getters.getUserSettPropLen;

                  // console.log('val2----');
                  // console.log(val2.length);
                  // console.log(val2Len);

                  if(val2.length < val2Len) {
                        this.$cookies.remove("_ts_access_token");
                        this.$cookies.remove("_ts_role");
                        this.$cookies.remove("_ts_agency");
                        this.$cookies.remove("_ts_agency_id");
                        this.$router.push("/");
                  }
                  
            }
           
      },

      CookieRemove: function() {
            this.$cookies.remove("_ts_access_token");
            this.$cookies.remove("_ts_role");
            this.$cookies.remove("_ts_team");
            this.$cookies.remove("_ts_firstname");
            this.$cookies.remove("_ts_lastname");
            this.$cookies.remove("_ts_team_id");
            this.$cookies.remove("_ts_agency");
            this.$cookies.remove("_ts_agency_id");
            this.$cookies.remove("_ts_user_id");
            this.$router.push("/");

            console.log('CR !!');
      },

      handleError(errorCode, goToLog, e) {

            this.error = `(${errorCode}) ${e.message}` || "Something went wrong!";
            console.log('hE -'+this.error);

            if (e.customStatus === 'ERR-DIFF' || e.customStatus === 'ERR-SETT') {
            this.error = e.message;
            console.log('hE-i -'+this.error);
            this.CookieRemove.call(this);
            }

            if (goToLog && e.response.status === 401) {
                  this.CookieRemove.call(this);
            }
            
      },

      getSettingFromArr(arr, sId, isLog) {

            let idx = arr.findIndex(x => x.settingsId == sId);
            if (idx === -1) {
                  return undefined; 
            }

            let rv = arr[idx].value;

            if(isLog) {
                  rv = !!+rv;
            }
            return rv;
      },

      monthDays(date, monthAdd) {

            let d = new Date(date);
            let dUtc = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(),0,0,0));

            // console.log('dUtc');
            // console.log(dUtc);
            // console.log([new Date(moment(dUtc).add(monthAdd, 'months').startOf('month')), new Date(moment(dUtc).add(monthAdd, 'months').endOf('month'))]);

            return [new Date(moment(dUtc).add(monthAdd, 'months').startOf('month')), new Date(moment(dUtc).add(monthAdd, 'months').endOf('month'))];
      },

      getDaysArray(start, end) {
            
            for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
                arr.push(new Date(dt));
            }
            return arr;
      },


      getDayName2(date) {
            
            const options = { weekday: 'long'}; 
            const formattedDate = date.toLocaleString('en-US', options); 
            return formattedDate; 
      },

      getMonthYear(date) {
            
            const options = { year: 'numeric', month: 'long', timeZone: 'UTC' }; 
            const formattedDate = date.toLocaleString('en-US', options); 
            return formattedDate; 
      },

      getFullTxtDate(date) {
            
            const options = { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric' }; 
            const formattedDate = date.toLocaleString('en-US', options); 
            return formattedDate; 
      },

      convertToYMD(dateString) {
            const date = new Date(dateString);
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const regex = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}$/;

            if (regex.test(dateString)) {
                  return date.toLocaleString('sv-SE', options).split(' ')[0];
            }
            else {
                  return dateString;     
            }

           
      },          

 
      setTheme(dark) {

            const bd = document.body;
            const el =  document.getElementById('dark-style');

            if(dark) {
                  bd.classList.add('dark-mode');

                  let styles = '::-webkit-scrollbar { width: 12px;}';
                  styles += ' ::-webkit-scrollbar-track {background: #2c3e50; border: 1px solid #000;}';
                  styles += ' ::-webkit-scrollbar-thumb {background: #1c2630; border: 1px solid #000;}';
                  styles += ' ::-webkit-scrollbar-thumb:hover {background: black;}';
                  styles += ' ::-webkit-scrollbar-thumb:hover {background: black;}';
                  styles += ' .vgt-input::placeholder {color: #d1d1d1 !important; opacity: 0.8 !important;}';
                  styles += ' .vgt-input::ms-input-placeholder {color: #d1d1d1 !important; opacity: 0.8 !important;}';

                  if (!(typeof(el) != 'undefined' && el != null))
                  {
                        var css = document.createElement('style');
                        css.type = 'text/css';
                        css.setAttribute("id", "dark-style");
                        
                        if (css.styleSheet)
                              css.styleSheet.cssText = styles;
                        else
                              css.appendChild(document.createTextNode(styles));
                        
                        document.getElementsByTagName("head")[0].appendChild(css); 
                  }
            }
            else {
                  bd.classList.remove('dark-mode');

                  if (typeof(el) != 'undefined' && el != null)
                  {
                        el.remove();
                  }
            }

      },

      noSeparator(val) {

            if (val == null) {
                return val;  
            }

            val = parseFloat(val).toFixed(2);

            var sign = 1;
            if (val < 0) {
            sign = -1;
            //val = -val;
            val = parseFloat(-val).toFixed(2);
            }
            // trim the number decimal point if it exists
            let num = val.toString().includes('.') ? val.toString().split('.')[0] : val.toString();
            let len = num.toString().length;
            let result = '';
            let count = 1;

            for (let i = len - 1; i >= 0; i--) {
            result = num.toString()[i] + result;
            if (count % 3 === 0 && count !== 0 && i !== 0) {
                  result = ' ' + result;
            }
            count++;
            }

            // add number after decimal point
            if (val.toString().includes('.')) {
            result = result + '.' + val.toString().split('.')[1];
            }
            // return result with - sign if negative
            return sign < 0 ? '-' + result : result;

      },

      yearList() {
            let newArr = [];
            let currYear = this.$store.getters.getCurrYear;

            for (let i = currYear-2; i <= currYear; i++) {
                  newArr.push({no: i, value: String(i)});
            }
            return newArr;
      },


      onlyNumber($event) {
            
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) ) {
               $event.preventDefault();
            }
      },


      uFnCheck(functionId) {

            if (isNaN(functionId)) {
                  return false;
            }

            return this.$store.getters.userFunction.includes(functionId);
      },

      async copyToClipboard(txt) {
            try {
              await navigator.clipboard.writeText(txt);
              console.log('Copied:', txt);
            } catch (err) {
              console.error('Error: ', err);
            }
      },

      randomRGB() {
            const losowyKanal = () => Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
            return '#' + losowyKanal() + losowyKanal() + losowyKanal();
      },

      agencyLogoClass(agencyId) {
            let className = 'log-pg';

            if (agencyId == 1) {
                  className = 'log-lqt';
            }
            if (agencyId == 3) {
                  className = 'log-yotta';
            }
            if (agencyId == 5) {
                  className = 'log-performics';
            }
            if (agencyId == 6) {
                  className = 'log-pmx';
            }
            if (agencyId == 7) {
                  className = 'log-zenith';
            }
            if (agencyId == 8) {
                  className = 'log-starcom';
            }
            if (agencyId == 9) {
                  className = 'log-spark';
            }

            return className;
      }

}