<template>
      <Loader :loader="loader" />

      <base-dialog :show="error != null" :showCloseIcon="false" title="Something Fishy" @close="error == null">
            <template v-slot:content>                  
                  <div class="inv-wrapper nwd-wrap">
                        <div class="inv-form-item">
                              <label>{{error}}</label>    
                        </div>
                  </div>
            </template>
             <template v-slot:actions>
                  <base-button class="d-btn d-edit" @click="error=null" title="Close"><i class="fas fa-times"></i></base-button>                  
            </template>
      </base-dialog>


      <base-dialog :show="showDialog" :title="dialogTitle" @close="closeDialog" :windowWidth="720">
            <template v-slot:content>
                  
                  <div class="inv-wrapper nwd-wrap j-wrap">
                        <div v-if="!showDialogNewJob" class="u-form-item">
                              <label>Job Id</label>
                              <div class="f-item">
                                    {{jobData.JobId}}
                              </div>                                      
                        </div>
                        <div class="u-form-item">
                              <label>Job No</label>
                              <div v-if="showDialogNewJob" class="f-item">
                                     <input type="text" name="JobNo" id="JobNo" v-model="jobData.JobNo">
                              </div>      
                              <div v-else class="f-item">
                                    {{jobData.JobNo}}       
                              </div>                               
                        </div>
                        <div class="u-form-item">
                              <label>Client Name</label>
                               <div v-if="showDialogNewJob" class="f-item">
                                     <vue-select 
                                          v-model="jobData.ClientId" 
                                          :options="clients" 
                                          label-by="ClientName"
                                          value-by="ClientId" 
                                          searchable 
                                          clear-on-close 
                                          close-on-select  />      
                              </div>        
                              <div v-else class="f-item">
                                    {{jobData.ClientName}}
                              </div>                                    
                        </div>
                        <div class="u-form-item">
                              <label>Job Name</label>
                              <div class="f-item">
                                     <input type="text" name="JobName" id="JobName" v-model="jobData.JobName">
                              </div>                                      
                        </div>  
                        <div class="u-form-item">
                              <label>Job Year</label>
                              <div class="f-item">
                                   {{jobData.JobYear}}
                              </div>                                      
                        </div>      
                        <div class="u-form-item">
                              <label>Owner</label>
                              <div class="f-item">
                                     <vue-select 
                                          v-model="jobData.OwnerId" 
                                          :options="jobOwners" 
                                          label-by="Name"
                                          value-by="Id" 
                                          searchable 
                                          clear-on-close 
                                          close-on-select  />   
    
                              </div>                                       
                        </div>      

                  </div>
                  
            
            </template>

            <template v-slot:actions>
                  <base-button class="d-btn d-edit" @click="closeDialog" title="Cancel"><i class="fas fa-times"></i></base-button>
                  <base-button class="d-btn d-edit" @click="onSaveClick()" title="Save" :disabled="this.jobData.OwnerId == 0 || this.jobData.JobNo == '' || this.jobData.JobName == '' "><i class="fas fa-save"></i></base-button>
            </template>
      </base-dialog>



      <div class="inner-wrap3 nwd-wrap">
     
            <h3>Jobs</h3>
                  <div class="criteria">

                        <div v-if="uFnCheck(11)" class="control-wrap">
                              <span>Agency: &nbsp;</span>
                              <vue-select 
                              v-model="agencyId" 
                              :options="agencies" 
                              label-by="Description" 
                              value-by="Id" 
                              :blur="onChange"
                              close-on-select />                               
                        </div>  
                        <div class="control-wrap">
                              <span>Year: &nbsp;</span>
                              <vue-select 
                              v-model="year" 
                              :options="years" 
                              label-by="value" 
                              value-by="no" 
                              :blur="onChange"
                              close-on-select />  
                              <span>New job: &nbsp;</span>
                              <button title="New job" class="d-btn new-d" name="newJob" @click="newJobClick()"><i class="fas fa-plus"></i></button>      
                        </div>  
                  </div>

            <div class="center-pan">
                  <vue-good-table
                        :columns="table1columns"
                        :rows="table1rows"
                        :line-numbers="false"
                        :search-options="{enabled: true}"
                        :sort-options="{enabled: true}"  
                        :pagination-options="{
                              enabled: true,
                              mode: 'records'
                        }" 
                                    
                  >
                        <template v-slot:emptystate>
                        No data
                        </template>

                        <template v-slot:table-row="props">
                              <div v-if="props.column.field == 'Action'">
                                          <button title="Edit" class="d-btn" :name="props.row.JobId" @click="onBtnClick(props.row.JobId, 'edit')"><i class="fas fa-edit"></i></button>
                                          <button title="Delete" class="d-btn" :name="props.row.JobId" @click="onBtnClick(props.row.JobId, 'del')"><i class="fas fa-trash"></i></button>
                              </div>
                              <span v-else>
                                    {{props.formattedRow[props.column.field]}}
                              </span>
                        </template>
                        
                  </vue-good-table>
            </div>        

      </div>
  
</template>

<script>
import shared from "@/shared";
// import moment from "moment";
import Loader from '../../components/Loader.vue';
import adminService from "@/services/admin.js";



export default {

      components: {
            Loader,           
      },

      data: function () {

            return {
                  year: new Date().getFullYear(),
                  showNav: true,
                  error: null,
                  loader: false,
                  showDialog: false,
                  showDialogNewJob: false,
                  dialogTitle: '',
                  settDarkMode: false, 
                  selectedJobId: null,       
                  years: [],
                  agencyId: this.$store.getters.userData.agencyId,
                  agencies: [],
                  jobOwners: [],
                  clients: [],
                  table1columns: [
                  {
                  label: 'Job Id',
                  field: 'JobId',
                  tdClass: 'text-f14'
                  },
                  {
                  label: 'Job No',
                  field: 'JobNo',
                  tdClass: 'text-f14'
                  },
                  {
                  label: 'Client Name',
                  field: 'ClientName',
                  tdClass: 'text-f12'
                  },
                  {
                  label: 'Job Name',
                  field: 'JobName',
                  tdClass: 'text-f12'
                  },
                  {
                  label: 'Job Year',
                  field: 'JobYear',
                  tdClass: 'text-f14'
                  },
                  {
                  label: 'Owner',
                  field: 'Owner',
                  tdClass: 'text-f14'
                  },
                  {
                  label: '',
                  field: 'Action',
                  tdClass: 'text-center',
                  sortable: false,
                  width: '128px',
                  }
                  ],
                  table1rows: [],
                  jobData: {
                        JobId:0,
                        ClientId: 0,
                        ClientName:"",
                        JobNo:"",
                        JobName:"",
                        JobNoName:"",
                        JobYear: new Date().getFullYear(),
                        OwnerId: 49,
                        Owner:""
                  }
                  
            };
      },

      created() {
            this.uFnCheck = shared.uFnCheck;
            this.CookieRemove = shared.CookieRemove;
            this.yearList = shared.yearList;

            this.years = this.yearList();
            this.years.push({no: -1, value: '-All-'});

            var dzisiaj = new Date();           
            this.year = dzisiaj.getFullYear();

      },

       mounted() {
            this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
            this.CookieCheckGoToLogin();

            this.getSettingFromArr = shared.getSettingFromArr;
            this.setTheme = shared.setTheme;


            this.settDarkMode = this.getSettingFromArr(this.$store.getters.userSettings, 8, true);
            this.setTheme(this.settDarkMode);

            
            
            const data = {ResultName: "AgencyList"};
            adminService
                  .uniRap(data)
                  .then((response) => {

                  if (response.status === 200) {                  
            
                        this.agencies = response.data;    

                         setTimeout(() => {                 
                              this.agencyId = parseInt(this.$store.getters.userData.agencyId);
                              this.onChange();
                              
                         },250);
                  }
                  
                  })
                  .catch((e) => shared.handleError.call(this, '103', true, e));

            

            
            //  setTimeout(() => {     
            //        var dzisiaj = new Date();           
            //        this.year = dzisiaj.getFullYear();
            //  },380);

      },

      methods: {

            closeDialog() {
                  this.showDialog = false;
                  this.showDialogNewJob = false;
                  this.dialogTitle = '';
            },


            clientList() {

                  adminService
                  .clientList()
                  .then((response) => {

                  if (response.status === 200) {          
                                               
                              this.clients = response.data;   
                              
                              const indexArr = this.clients.findIndex(object => {
                                    return object.ClientId === 0;
                              }); 

                              if (indexArr !== -1) {
                                    this.clients[indexArr].ClientName = '--Select--';
                              }
                              //this.clients.unshift({Id: 0, Name: '--Select--'});
                              
                              // console.log("++++ this.clients+++");
                              // console.log(this.clients);
                  }
                  
                  })
                  .catch((e) => shared.handleError.call(this, '104', true, e))
                  .finally(() => {
                        this.loader = false;                  
                   
                  });

            },

            ownersList() {

                   adminService
                  .usersAgencyList(this.agencyId)
                  .then((response) => {

                  if (response.status === 200) {          
                                               
                              this.jobOwners = response.data;

                              this.jobOwners.forEach((element, index) => {
                                    this.jobOwners[index].Name = element.FirstName+' '+element.LastName;
                              });

                              this.jobOwners.unshift({Id: 0, Name: '--Select--'});
                              
                              // console.log("++++ this.jobOwners+++");
                              // console.log(this.jobOwners);
                  }
                  
                  })
                  .catch((e) => shared.handleError.call(this, '105', true, e))
                  .finally(() => {
                        this.loader = false;
                  
                   
                  });

            },


            newJobClick() {


                  // console.log("----button----");  
                  //       console.log(this.showDialogNewJob); 
                  //       console.log('owner:'+this.jobData.OwnerId); 
                  //       console.log('jobNo:'+this.jobData.JobNo); 


                  this.jobData = {
                        JobId:0,
                        ClientId: 0,
                        ClientName:"",
                        JobNo:"",
                        JobName:"",
                        JobNoName:"",
                        JobYear: new Date().getFullYear(),
                        OwnerId: 0,
                        Owner:""
                  }




                  this.showDialog = true;
                  this.showDialogNewJob = true;
                  this.dialogTitle = 'New Job';
            },



            onBtnClick(JobId, type) {

                  console.log('JobId');
                  console.log(JobId+' t:'+type);

                  this.selectedJobId = JobId;

                  if(type === 'edit') {
                  
                        adminService
                        .jobGet(this.selectedJobId, this.agencyId)
                        .then((response) => {

                        if (response.status === 200) {          

                              this.jobData = response.data;                       

                              //  console.log("----response.data----");
                              //  console.log(this.jobData );

                              this.dialogTitle = ''+this.jobData.JobNo;

                              this.showDialog = true;
                              this.showDialogNewUser = false;
                              this.dialogTitle = ''+this.jobData.JobNo;
                                                
                        }
                        
                        })
                        .catch((e) => shared.handleError.call(this, '106', false, e))
                        .finally(() => {
                        this.loader = false;                  
                        
                        });


                       

                  }
                  else {

                        let d = {
                              jobId: JobId,
                              ownerId: -1,
                              jobName: '',
                              agencyId: this.agencyId 
                        };

                        this.jobEdit(d);

                  }


            },

            onChange() {
                  this.loader = true;
                  
                  console.log('data change');

                  this.ownersList();
                  this.clientList();


                  adminService
                  .jobAgencyList(this.year, this.agencyId)
                  .then((response) => {

                  if (response.status === 200) {          
                       
                              this.table1rows = response.data;

                        // console.log("----table1rows----");
                        // console.log(this.table1rows);
                       
                                          
                  }
                  
                  })
                  .catch((e) => shared.handleError.call(this, '107', false, e))
                  .finally(() => {
                  this.loader = false;
                  
                   
                  });

            },

            jobEdit(data) {

                        console.log("----jobEdit - data----");
                         console.log(data);

                        adminService
                        .jobEdit(data)
                        .then((response) => {
                              

                              if ((response.status === 200) & (response.data.Code === "OK")) {
                                     console.log('response------');
                                     console.log(response);

                                    console.log('--SAVED--');
                                    this.onChange();
                        
                              } 
                              else {
                                    this.error = response.data.Text;
                              }
                           

                        })
                        .catch((e) => shared.handleError.call(this, '108', true, e));


            },


             onSaveClick() {
                  console.log("----SAVE----");  
                  console.log(this.jobData);  
               


                  let data = {
                        jobId: this.selectedJobId,
                        clientId: this.jobData.ClientId,
                        ownerId: this.jobData.OwnerId,
                        jobNo: this.jobData.JobNo,
                        jobName: this.jobData.JobName,
                        agencyId: this.agencyId                                      
                  }

                  // console.log(data);
                  
                  
                  if (this.showDialogNewJob) {

                        adminService
                        .jobAdd(data)
                        .then((response) => {
                              

                              if ((response.status === 200) & (response.data.Code === "OK")) {
                                    // console.log('response------');
                                    // console.log(response);

                                    console.log('--SAVED--');
                                    this.onChange();
                        
                              } 
                              else {
                                    this.error = response.data.Text;
                              }
                              // console.log('response------');
                              // console.log(response.data);


                        })
                        .catch((e) => shared.handleError.call(this, '109', true, e));


                  } 
                  else {
                         this.jobEdit(data);      
                  }


                  this.showDialog = false;
                  this.showDialogNewJob = false;



            },


      }

}
</script>

<style>


.dialog-box.edit .nwd-wrap.j-wrap .f-item input {
    width: 424px;
    color: #667;
}


.dialog-box.edit .nwd-wrap.j-wrap .vue-select {
      margin: initial;
      margin: unset;
      width: 424px;
}

</style>